import { EventSerializer, LndrTapButtonEventBuilder } from '@careem-public/events-tenants_laundry_by_washmen';

import { trackCareemEvent } from './trackCareemEvent';

const TENANT_SERVICE = 'laundry';

export const careemButtonEvent = (buttonName: string, buttonSize: string, buttonType: string) => {
  const { pathname } = window.location;

  const buttonTapEvent = new LndrTapButtonEventBuilder();

  const event = buttonTapEvent
    .tenantService(TENANT_SERVICE)
    .pageName(pathname)
    .buttonName(buttonName)
    .buttonSize(buttonSize)
    .buttonType(buttonType)
    .build();

  const eventSerializer = new EventSerializer();
  const serializedEvent = eventSerializer.serialize(event);

  trackCareemEvent(serializedEvent, 'button event');
};
