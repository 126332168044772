import { EventSerializer, LndrViewPageEventBuilder } from '@careem-public/events-tenants_laundry_by_washmen';

import { trackCareemEvent } from './trackCareemEvent';

const TENANT_SERVICE = 'laundry';

export const careemScreenEvent = (pageName: string) => {
  const screenEventBuilder = new LndrViewPageEventBuilder().tenantService(TENANT_SERVICE).pageName(pageName);

  const event = screenEventBuilder.build();
  const eventSerializer = new EventSerializer();
  const serializedEvent = eventSerializer.serialize(event);

  trackCareemEvent(serializedEvent, 'screen event');
};
